<template>
    <div v-if="results" :id="containerName" :class="containerClass">
        <canvas style="z-index: 99999" :id="canvasName"></canvas>
    </div>
</template>

<script>

    import Chart from 'chart.js';
    export default {
        props: ['results', 'options', 'containerClass'],
        data() {
            return {
                containerName: `canvasContainer-${Math.floor(Math.random()*10001)}`,
                canvasName: `chartCanvas-${Math.floor(Math.random()*10001)}`,
            }
        },
        methods: {
            renderChart: function() {
                let chartData = (this.results.chartData);
                let ctx = document.getElementById(this.canvasName).getContext('2d');
                var datasets = [];
                let backgroundColors = [];
                var numColors = 0;

                if (chartData.type == 'line') {
                    numColors = Object.keys(chartData.datasets).length;
                } else {
                    numColors = chartData.labels.length;
                }

                if (chartData.colors) {
                    backgroundColors = chartData.colors;
                } else if (numColors <= 2) {
                    backgroundColors = ["#29b6f6","#01579B"];
                } else if (numColors <= 4) {
                    backgroundColors = ["#81d4fa","#29b6f6","#039be5","#0277bd"];
                } else {
                    backgroundColors = ["#b3e5fc","#81d4fa","#4fc3f7","#29b6f6","#03a9f4","#039be5","#0288d1","#0277bd","#01579B"];
                }

                let i = 0;

                for (let setName in chartData.datasets) {
                    if (chartData.type === 'line') {
                        datasets.push({
                            data: chartData.datasets[setName],
                            backgroundColor: backgroundColors[i],
                            borderColor: backgroundColors[i],
                            borderWidth: 2 ,
                            label: setName,
                            fill: false
                        });
                    } else {
                        datasets.push({
                            data: chartData.datasets[setName]['data'],
                            backgroundColor: chartData.datasets[setName]['backgroundColor'] ? chartData.datasets[setName]['backgroundColor'] : backgroundColors,
                            borderColor: backgroundColors,
                            borderWidth: 0,
                            label: setName,
                            fill: true
                        });
                    }


                    i++;
                }

                new Chart(ctx, {
                    type: chartData.type ? chartData.type : 'bar',
                    data: {
                        labels: chartData.labels,
                        datasets: datasets, 
                    },
                    options: this.options,
                });
            },
            clearChart: function() {
                // remove canvas
                let canvasContainer = document.getElementById(this.containerName);
                let chartCanvas = document.getElementById(this.canvasName);
                canvasContainer.removeChild(chartCanvas);
                
                // add canvas
                let newChartCanvas = document.createElement('canvas');
                newChartCanvas.setAttribute('id', this.canvasName);
                canvasContainer.appendChild(newChartCanvas);
            },
        },
        watch: {
            results: {
                deep: true,
                handler: function() {
                    let rendered = false;
                    do {
                        if (this.results !== undefined && document.getElementById(this.containerName)) {
                            this.clearChart();
                            if (this.results.chartData.datasets) {
                                this.renderChart();
                            }
                        }
                        rendered = true;
                    } while(rendered === false)

                }
            }
        }
    }
</script>

<style scoped>

</style>